import { 
    FC,
    useContext,
    useState,
    useCallback
} from 'react';
import { 
    Table,
    Typography,
    List,
    Grid
} from 'antd';
import { Timestamp, DocumentSnapshot } from 'firebase/firestore';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

import { Form, DocDataWithId } from '../../types/System.types';
import { ArtifactsContext } from '../../providers/ArtifactsProvider';
import FlexCard from '../atoms/FlexCard';

const { useBreakpoint } = Grid;
const { Title, Text } = Typography;

const columns: ColumnsType<Form> = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: '30%',
    },
    {
        title: 'Form Number',
        dataIndex: 'formNumber',
        key: 'formNumber',
        width: '20%',
    },
    {
        title: 'Form Type',
        dataIndex: 'formType',
        key: 'formType',
        width: '20%',
    },
    {
        title: 'Created',
        dataIndex: ['meta', 'created'],
        key: 'created',
        width: '30%',
        render: (timestamp: Timestamp) => {
            if (timestamp instanceof Timestamp) 
                return timestamp.toDate().toLocaleString();
            return 'Invalid Date';
        },
    },
];

const FormsTable: FC = () => {
    const context = useContext(ArtifactsContext);
    if (!context) throw new Error('ArtifactsContext is undefined');
    const { forms } = context;
    
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const { data, error, loading, setLimit, setStartAt } = forms;
    const navigate = useNavigate();
    const { md } = useBreakpoint();

    const handleTableChange = useCallback((pagination: any) => {
        const { current: newCurrent, pageSize: newPageSize } = pagination;
        
        if (newPageSize !== pageSize) {
            setPageSize(newPageSize);
            setLimit(newPageSize);
            setCurrent(1);
            setStartAt(undefined);
        } else if (newCurrent !== current) {
            setCurrent(newCurrent);
            if (newCurrent === 1) {
                setStartAt(undefined);
            } else if (data.length > 0) {
                const lastDocIndex = (newCurrent - 1) * pageSize - 1;
                if (lastDocIndex >= 0 && lastDocIndex < data.length) {
                    const lastDoc = data[lastDocIndex] as DocDataWithId & { ref: DocumentSnapshot };
                    setStartAt(lastDoc.ref);
                } else {
                    console.warn('Invalid document index for pagination');
                    setStartAt(undefined);
                }
            }
        }
    }, [current, pageSize, setLimit, setStartAt, data]);

    if (error) {
        return <Text type="danger">Error: {error.message}</Text>;
    }
    
    if (!md) {
        return (
            <List
                dataSource={data as Form[]}
                loading={loading}
                renderItem={(item: Form) => (
                    <FlexCard 
                        key={item.docId}
                        alignStart
                        hoverable
                        onClick={() => navigate(`/forms/${item.docId}`)}
                        style={{ marginBottom: 16, cursor: 'pointer' }}
                    >
                        <Title 
                            level={5}
                            style={{ margin: 0 }}
                        >
                            {item.title}
                        </Title>
                        <Text>
                            Form Number: {item.formNumber}
                        </Text>
                        <Text>
                            Form Type: {item.formType}
                        </Text>
                        <Text>
                            Created: {
                                item.meta?.created instanceof Timestamp 
                                    ? item.meta.created.toDate().toLocaleString() 
                                    : 'Invalid Date'
                            }
                        </Text>
                    </FlexCard>
                )}
                pagination={{
                    current,
                    pageSize,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total) => `Total ${total} items`,
                    onChange: handleTableChange,
                }}
            />
        );
    }

    return (
        <Table
            style={{
                width: '100%',
            }}
            dataSource={data as Form[]}
            columns={columns}
            loading={loading}
            rowKey="docId"
            pagination={{
                current,
                pageSize,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
                onClick: () => navigate(`/forms/${record.docId}`),
                style: { cursor: 'pointer' },
            })}
            scroll={{ x: 'max-content' }}
        />
    );
};

export default FormsTable;