import { StorageReference } from "firebase/storage";
import { Timestamp, FieldValue, DocumentData } from "firebase/firestore";
import { FormStateId, RecordStatus, formStateId, formStateDescription } from "./CLW.Config.types";
export type TimeType = Timestamp | FieldValue;

export { formStateId, formStateDescription, RecordStatus };
export type { FormStateId };

/**
 * Form document, such as a quote or invoice.
 * Is a DB document.
 * Stored in the collection `forms/`.
 */
export type Form = {
    /** Unique identifier for the form 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Title of the form */
    title?: string;
    /** Form number */
    formNumber?: string;
    /** Organization associated with the form */
    organization: string;
    /** User roles associated with the form */
    userRoles: { 
        [key: string]: UserDBRole 
    };
    /** User group associated with the form (singular) */
    userGroup?: string;
    /** Type (formTypeId) of the form */
    formType: FormType["docId"];
    /** Current version (formVersionId) of the form */
    currentVersionId: FormVersion["docId"];
    /** External IDs from other systems */
    externalIds?: Record<string, string>;
    /** Metadata for the form */
    meta?: Meta;
    /** Subcollection `forms/{formId}/formVersions/` for the versions of this form */
};

/**
 * Represents form version data.
 * Is a DB document.
 * Stored in the sub-collection `forms/{formId}/formVersions/`.
 */
export type FormVersion = {
    /** The form version id
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** System field used to check user access privileges */
    _testField?: any;
    /** The state of the form version */
    state: FormStateId;
    /** Form data (### This contains the dynamic/organization data) */
    [key: string]: any;
    /** The default layout for the form type */
    layoutId?: DocId;
    /** Associated files */
    associatedFiles?: { 
        /** The associated file id, maps to File type documents in the collection `files/` */
        [fileId: DocId]: { 
            /** Notes about the file association */
            fileNotes?: string;
            /** Tags for the file association */
            fileTags?: string[];
            /** metadata for the file association */
            meta?: Meta;
        }
    };
    /** Metadata for the form data */
    meta?: Meta;
    /** Multiple Subcollections `forms/{formId}/{fieldId}/` each for the line items of tables and lists in the form */
};

/**
 * Defines the structure for a document type in the workflow.
 * Is a DB document.
 * Stored in the collection `formTypes/`.
 */
export type FormType = {
    /** Unique identifier for the form type 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** The possible states for the form type */
    possibleStates: {  
        [key: string]: FormState 
    };
    /** The default layout for the form type */
    defaultLayoutId: DocId;
    /** The name of the starting state for new documents of this type */
    initialState?: FormStateId;
    /** Description of the document type */
    description?: Description;
    /** Metadata for the form type */
    meta?: Meta;
};

/**
 * Implements the logic for a form state in the workflow.
 * Is a map or object field.
 */
export type FormState = {
    /** The name of the state 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: FormStateId;
    /** Description of the document state */
    description?: Description;
    /** Array of state formStateId that this state can transition to */
    allowedNextStates: FormStateId[];
    /** Rules that enable the state */
    entryRules?: { 
        [key: string]: FormRule 
    };
    /** Rules that enable the state */
    exitRules?: { 
        [key: string]: FormRule 
    };
    /** Actions that are performed when entering the state */
    onEntryActions?: { 
        [key: string]: FormAction 
    };
    /** Actions that are performed when exiting the state */
    onExitActions?: { 
        [key: string]: FormAction 
    };
    /** Metadata for the form state */
    meta?: Meta;
};

/**
 * Represents a rule for a form state.
 * Is a map field.
 */
export type FormRule = {
    /** The function name (in code) for the rule */
    functionName: string;
    /** Condition for the rule */
    condition: () => boolean;
    /** Error message for the rule */
    errorMessage?: string;
    /** Description of the rule */
    description?: Description;
    /** Metadata for the rule */
    meta?: Meta;
};

/**
 * Represents an action for a form state.
 * Is a map or object field.
 */
export type FormAction = {
    /** The action data */
    action: () => void;
    /** Description of the action */
    description?: Description;
    /** Metadata for the action */
    meta?: Meta;
};


/**
 * Represents the layout structure for form components.
 * Is a DB document.
 * Stored in the collection `layouts/`.
 */
export interface Layout {
    /** Unique identifier for the layout 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Type of component */
    component: string;
    /** Field identifier, represents the field name in forms that will store data for the control */
    field: string;
    /** Properties for the component */
    props: {
        /** Children LayoutComponent */
        children?: LayoutComponent[];
        /** Additional component properties */
        [key: string]: any;
    };
    /** Description of the layout */
    description?: Description;
    /** Metadata for the layout */
    meta?: Meta;
}

/**
 * Represents a child component in the form layout.
 * Is a map or object field.
 */
export interface LayoutComponent {
    /** Unique identifier for the component 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Type of the component */
    component: string;
    /** Field identifier, represents the field name in forms that will store data for the control */
    field: string;
    /** Properties of the component */
    props: {
        /** Text content or label for the component */
        children: string;
        /** Additional dynamic properties */
        [key: string]: any;
        /** For Tables: Data type for the table cell */
        celltype?: string;
        /** For Lists: Data type for the list */
        listtype?: string;
    };
    /** Description of the component */
    description?: Description;
    /** Metadata for the component */
    meta?: Meta;
}

/**
 * Represents an associated file in the system.
 * Is a DB document.
 * Stored in the collection `files/`.
 */
export type File = {
    /** Unique identifier for the associated document 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Type of the associated document */
    associatedFileType: string;
    /** Notes about the associated document */
    associatedFileNotes?: string;
    /** Storage reference for the associated document */
    associatedFileStorageReference: StorageReference;
    /** Metadata for the associated file */
    meta?: Meta;
};

/**
 * Represents a user role in the system.
 * Is a map field.
 */
export type UserRole = {
    /** Optional array of permissions associated with the role */
    permissions?: { 
        [key: string]: string 
    };
    /** Description of the user role */
    description?: Description;
    /** Metadata for the user role */
    meta?: Meta;
};

export enum UserDBRole {
    Owner = "owner",
    Editor = "editor",
    Viewer = "viewer"
}

/**
 * Represents a description of a data object.
 * Is a map field.
 */
export type Description = {
    /** Short label for the action */
    shortLabel?: string;
    /** Short description of the action */
    shortDescription?: string;
    /** Long label for the action */
    longLabel?: string;
    /** Long description of the action */
    longDescription?: string;
};

/**
 * Represents metadata for top level objects.
 * Is a map field.
 */
export type Meta = {
    /** Timestamp of the creation */
    created?: TimeType;
    /** Timestamp of the last modification */
    lastModified?: TimeType;
    /** Purpose of the object */
    purpose?: string;
    /** User ID associated with the object */
    userId?: string;
    /** Type of the event */
    agentType?: AgentType;
    /** Version of the object */
    version?: string;
};

/**
 * Represents a document identifier in the document database.
 * This field is system generated and not present in the document data as a field.
 * Generally, document write operations should not include this field.
 */
export type DocId = string;

/**
 * Represents a document data with an identifier.
 */
export type DocDataWithId<T extends DocumentData = DocumentData> = T & { docId?: DocId };

/**
 * Represents an event log entry in the system.
 * Is a DB document.
 * Stored in the collection `eventLogs/`.
 */
export type EventLogEntry = {
    /** Unique identifier for the log entry 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Timestamp of when the event occurred */
    timestamp: TimeType;
    /** Type of the event */
    agentType: AgentType;
    /** Category of the event */
    eventCategory: EventCategory;
    /** Description of the event */
    description: string;
    /** Associated form ID, if applicable */
    formId?: string;
    /** Associated user ID, if applicable */
    userId?: string;
    /** Changed values, if any */
    changedValues?: {
        [key: string]: {
            oldValue: any;
            newValue: any;
        };
    };
    /** Indicates if the event is an error */
    isError?: boolean;
    /** Additional metadata for the event */
    meta?: Meta;
};

/**
 * Enum representing the types of events that can be logged.
 * Is a Enum field.
 */
export enum AgentType {
    /** Represents a human user */
    User = "User",
    /** Represents an AI language model agent */
    LLMAgent = "LLMAgent",
    /** Represents a scheduling agent */
    Scheduler = "Scheduler",
    /** Represents the system itself */
    System = "System"
}

/**
 * Enum representing the categories of events.
 * Is a Enum field.
 */
export enum EventCategory {
    /** Event related to creating a new form */
    FormCreation = "FormCreation",
    /** Event related to updating an existing form */
    FormUpdate = "FormUpdate",
    /** Event related to changing the state of a form */
    FormStateChange = "FormStateChange",
    /** Event related to archiving a form */
    FormArchive = "FormArchive",
    /** Event related to system maintenance tasks */
    SystemMaintenance = "SystemMaintenance",
}

/**
 * Function type for performing a state transition.
 * @param newState The target FormState to transition to
 * @param formData Optional form data associated with the transition
 * @returns The new FormState after the transition
 * @throws {Error} If the transition is not allowed or if required data is missing
 */
export type SetFormState = (
    newState: string,
    formData?: undefined
) => string;

/**
 * Represents an type of record entity in the system, such as customer or project.
 * Is a DB document.
 * Stored in the collection `entities/`.
 */
export type Entity = {
    /** Unique identifier for the entity 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the entity */
    name: string;
    /** Status of the entity */
    status: RecordStatus;
    /** Description of the entity */
    description?: Description;
    /** Entity type layout for UI ren */
    layoutId?: DocId;
    /** Metadata for the entity */
    meta?: Meta;
    /** Subcollection `entities/{entityId}/records/` for the records of this entity type */
};

/**
 * Represents the properties of an entity in the system.
 * Is a DB document.
 * Stored in the subcollection `entity/{entityId}/records/`.
 */
export type EntityRecord = {
    /** Unique identifier for the entity property 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Status of the entity */
    status: RecordStatus;
    /** Metadata for the entity properties */
    meta?: Meta;
    /** Name of the entity property */
    name: string;
    /** Description of the entity property */
    description?: Description;
    /** Values of the entity property; keys map to Layout.field (layout field names) */
    [field: string]: any;
};

/**
 * Represents a user in the system.
 * Is a DB document.
 * Stored in the collection `users/`.
 */
export type User = {
    /** Unique identifier for the user 
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the user */
    name: string;
    /** Email of the user */
    email: string;
    /** Super user flag */
    super?: boolean;
    /** Metadata for the user */
    meta: Meta;
};

/**
 * Represents a template category in the system.
 * Is a DB document.
 * Stored in the collection `templates/`.
 */
export type Template = {
    /** Unique identifier for the template category
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Template category type */
    templateType: TemplateType;
    /** Name of the template category */
    name: string;
    /** Status of the template category */
    description?: Description;
    /** Current version of the template */
    currentVersionId: TemplateVersion["docId"];
    /** Metadata for the template category */
    meta?: Meta;
    /** Subcollection `templates/{docId}/versions/` for the versions of this template */
};

/**
 * Represents an individual template in the system.
 * Is a DB document.
 * Stored in the subcollection `templates/{templateCategoryId}/versions/`.
 */
export type TemplateVersion = {
    /** Unique identifier for the template
     * This field is system generated and not present in the document data as a field.
     * Generally, document write operations should not include this field.
     */
    docId?: DocId;
    /** Name of the template */
    name: string;
    /** Status of the template */
    status: RecordStatus;
    /** Description of the template */
    description?: Description;
    /** Metadata for the template */
    meta?: Meta;
    /** Template content; keys map to Layout.field (layout field names) */
    content: Form | Layout | Entity;
    /** Sub content for the template */
    subContent?: FormVersion | EntityRecord;
    /** Default layout for this template */
    defaultLayoutId: DocId;
};

/**
 * Enum representing the types of templates.
 * Is a Enum field.
 */
export enum TemplateType {
    /** Represents a form template */
    Form = "Form",
    /** Represents a layout template */
    Layout = "Layout",
    /** Represents an entity template */
    Entity = "Entity",
}

/**
 * Represents the data types that can be used in Table and List components.
 */
export type TableDataType = string | number | boolean | Timestamp;

/**
 * Returns the type of the value as a string.
 * @param value The value to get the type of
 * @returns The type of the value as a string
 */
export function tableTypeOf(value: TableDataType): string {
    if (value instanceof Timestamp) return 'timestamp';
    return typeof value;
}