import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator
} from 'firebase/functions';
import Chat from 'openai';
import { Form, FormVersion } from '../types/System.types';

import { environmentType } from '../config';

interface CallLanguageModelRequest {
    system?: string;
    prompt: string;
}
interface CallLanguageModelResponse {
    completion: Chat.ChatCompletion;
    pointsUsed: number;
}
interface CallAgentRequest {
    system?: string;
    prompt: string;
}
interface CallAgentResponse {
    result: { output: string };
    pointsUsed: number;
}
interface CreateNewOrganizationResponse {
    orgId: string;
}

const functions = getFunctions();

const checkAndUseEmulator = async () => {
    if (environmentType === 'development') {
        try {
            const response = await fetch('http://localhost:8080');
            if (response.ok) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
                console.log('Connected to Firebase Functions emulator');
            }
        } catch (error) {
            console.log('Firebase Functions emulator is not running');
        }
    }
};
checkAndUseEmulator();

export const callAgentFB = async (requestData: CallAgentRequest): Promise<CallAgentResponse> => {
    const callAgent = httpsCallable<CallAgentRequest, CallAgentResponse>(functions, 'callAgent');

    const constantRequestData = {
        system: 'You are an Architect. Write plain text.',
        model: 'gpt-4-1106-preview',
        ...requestData,
    };

    try {
        console.log('##MODEL REQUEST: ', constantRequestData?.prompt, constantRequestData);
        const result = await callAgent(constantRequestData);
        console.log('##MODEL RESULT DATA: ', result.data);
        return result.data;
    } catch (error) {
        console.error(`Error calling callCompletion function: ${error}`);
        throw new Error(`Failed to call language model: ${error}`);
    }
};

export const callLanguageModel = async (requestData: CallLanguageModelRequest): Promise<CallLanguageModelResponse> => {

    const callCompletion = httpsCallable<CallLanguageModelRequest, CallLanguageModelResponse>(functions, 'callCompletion');

    const constantRequestData = {
        system: 'You are an Architect. Write plain text.',
        model: 'gpt-4-1106-preview',
        temperature: 0.95,
        topp: 1,
        ...requestData,
    };

    try {
        console.log('##MODEL REQUEST: ', constantRequestData?.prompt, constantRequestData);
        const result = await callCompletion(constantRequestData);
        console.log('##MODEL RESULT DATA: ', result?.data?.completion?.choices[0]?.message?.content, result);
        return result.data;
    } catch (error) {
        console.error(`Error calling callCompletion function: ${error}`);
        throw new Error(`Failed to call language model: ${error}`);
    }
};

export const createNewOrganization = async (): Promise<CreateNewOrganizationResponse> => {

    const callCreateNewOrganization = httpsCallable<null, CreateNewOrganizationResponse>(functions, 'createNewOrganization');

    try {
        const result = await callCreateNewOrganization(null);
        console.log('Organization created: ', result);
        return result.data;
    } catch (error) {
        console.error(`Error calling createNewOrganization function: ${error}`);
        throw new Error(`Failed to create new organization: ${error}`);
    }
};


/**
 * Request object for creating a new form.
 */
export interface CreateFormRequest {
    formType: string;
    title?: string;
    organization?: string;
    userGroup?: string;
    setFormData?: Partial<Form>;
    setVersionData?: Partial<FormVersion>;
}

/**
 * Creates a new form using Firebase Cloud Functions.
 * @param {CreateFormRequest} requestData - The data required to create a new form.
 * @returns {Promise<string>} A promise that resolves to the ID of the created form.
 * @throws {Error} If the form creation fails.
 */
export const createForm = async (requestData: CreateFormRequest): Promise<string> => {
    const callCreateForm = httpsCallable<CreateFormRequest, string>(functions, 'createForm');

    try {
        const result = await callCreateForm(requestData);
        return result.data;
    } catch (error) {
        const errorMessage = `Failed to create form: ${error}`;
        console.error(`Error calling createForm function:`, error);
        throw new Error(errorMessage);
    }
};