import { useCallback, useContext } from 'react';
import { useBoundCollection } from '../data/useBoundCollection';
import { useAdd } from '../data/useAdd';
import { useSet } from '../data/useSet';
import { ArtifactsContext } from '../../providers/ArtifactsProvider';
import { EntityRecord, RecordStatus, DocId } from '../../types/System.types';

export const useEntityRecords = (entityType: string, testEntityPath?: string, testRecordsPath?: string) => {
  const { add } = useAdd();
  const { debouncedSet } = useSet();
  const artifactsContext = useContext(ArtifactsContext);

  if (!artifactsContext) {
    throw new Error('useEntityRecords must be used within an ArtifactsProvider');
  }

  const { entities } = artifactsContext;

  const {
    data: entityRecords,
    error: recordsError,
    loading: recordsLoading
  } = useBoundCollection<EntityRecord>({
    path: testRecordsPath ? 
      `${testEntityPath}/${entityType}/${testRecordsPath}` :
      entityType ?  
        `entities/${entityType}/records` : 
        '',
    enabled: !!entityType
  });

  const setValue = useCallback((recordId: DocId, field: string, value: any) => {
    const record = entityRecords.find(r => r.docId === recordId);
    if (record) {
      debouncedSet(`entities/${entityType}/records`, recordId, { [field]: value });
    }
  }, [entityRecords, entityType, debouncedSet]);

  const addRecord = useCallback(async (data: Partial<EntityRecord>) => {
    const newRecordId = await add(`entities/${entityType}/records`, data);
    return newRecordId;
  }, [add, entityType]);

  const updateRecordStatus = useCallback((recordId: DocId, newStatus: RecordStatus) => {
    setValue(recordId, 'status', newStatus);
  }, [setValue]);

  return {
    loading: entities.loading || recordsLoading,
    error: entities.error || recordsError,
    entityRecords,
    setValue,
    addRecord,
    updateRecordStatus,
  };
};