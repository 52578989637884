import { 
    useCallback,
    useContext
 } from 'react';
import {
    doc,
    setDoc,
    DocumentReference,
    writeBatch,
} from 'firebase/firestore';
import { debounce } from 'lodash';

import { DataContext } from '../../providers/DataProvider';
import { DocDataWithId, DocId } from '../../types/System.types';

/**
 * Interface for data set operations
 */
export interface UseSet {
    set: <T extends DocDataWithId = DocDataWithId>(path: string, docId: DocId, data: Partial<T>) => Promise<void>;
    debouncedSet: <T extends DocDataWithId = DocDataWithId>(path: string, docId: DocId, data: Partial<T>) => void;
    batchSet: (operations: Array<{ path: string; docId: DocId; data: any }>) => Promise<void>;
}

/**
 * Hook for managing Firestore data set operations
 * @param firestore - Firestore instance
 * @returns Object with set, debouncedSet, and batchSet functions
 */
export const useSet = (): UseSet => {
    /**
     * Sets data in Firestore
     * @param path - Document path
     * @param docId - Document ID
     * @param data - Data to set
     */

    const { firestore } = useContext(DataContext);

    const set = useCallback(async <T extends DocDataWithId = DocDataWithId>(path: string, docId: DocId, data: Partial<T>): Promise<void> => {
        try {
            const docRef: DocumentReference = doc(firestore, path, docId);
            await setDoc(docRef, data, { merge: true });
        } catch (error) {
            console.error(`Error in set operation: ${error}`);
            throw error;
        }
    }, [firestore]);

    /**
     * Debounced version of set function
     * Saves values to the database after a delay
     * @param path - Document path
     * @param docId - Document ID
     * @param updates - Data to set
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSet = useCallback(
        debounce(<T extends DocDataWithId = DocDataWithId>(path: string, docId: DocId, updates: Partial<T>) => {
            console.log('---debouncedSet - updates:', path, docId, updates);
            set(path, docId, updates).catch(error => {
                console.error(`Error in debouncedSet operation: ${error}`);
            });
        }, 350),
        [set]
    );

    /**
     * Performs batch set operations in Firestore
     * @param operations - Array of set operations
     * @example
     * const operations = [
     *   { path: 'users', docId: 'user1', data: { name: 'John Doe', age: 30 } },
     *   { path: 'posts', docId: 'post1', data: { title: 'New Post', content: 'Content here' } }
     * ];
     * await batchSet(operations);
     */
    const batchSet = useCallback(async (operations: Array<{ path: string; docId: DocId; data: any }>) => {
        const batch = writeBatch(firestore);
        try {
            operations.forEach(op => {
                const docRef = doc(firestore, op.path, op.docId);
                batch.set(docRef, op.data, { merge: true });
            });
            await batch.commit();
        } catch (error) {
            console.error(`Error in batchSet operation: ${error}`);
            throw error;
        }
    }, [firestore]);

    return { 
        set, 
        debouncedSet, 
        batchSet,
    };
};