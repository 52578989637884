/**
 * @file CLW.Config.types.ts
 * @description Configuration types and constants for Chicago Lightworks (CLW) system.
 * This file defines form states, record statuses, and template objects for forms, 
 * form versions, and layouts used in CLW's custom workflows.
 * These configurations maintain consistency and provide default structures throughout the application.
 * 
 * @module CLW.Config
 */

/**
 * Enum representing the possible states of a form in the system.
 */
export const formStateId = {
    Draft: "Draft",
    Submitted: "Submitted",
    UnderReview: "UnderReview",
    Approved: "Approved",
    Rejected: "Rejected",
    Archived: "Archived"
} as const;
export type FormStateId = typeof formStateId[keyof typeof formStateId];

/*
 * Title and description of form states
 */
export const formStateDescription: Record<(FormStateId | "*"), {
    title: string;
    description: string;
    action?: string;
}> = {
    [formStateId.Draft]: {
        title: "Draft",
        description: "Form is in draft mode, editable by the creator",
        action: "Draft"
    },
    [formStateId.Submitted]: {
        title: "Submitted for Review",
        description: "Form has been submitted for review",
        action: "Submit"
    },
    [formStateId.UnderReview]: {
        title: "Under Review",
        description: "Form is currently being reviewed by authorized personnel",
        action: "Review"
    },
    [formStateId.Approved]: {
        title: "Approved",
        description: "Form has been approved and is considered finalized",
        action: "Approve"
    },
    [formStateId.Rejected]: {
        title: "Rejected",
        description: "Form has been rejected and requires revision",
        action: "Reject"
    },
    [formStateId.Archived]: {
        title: "Archived",
        description: "Form is no longer active but retained for record-keeping",
        action: "Archive"
    },
    "*": {
        title: "Unknown State",
        description: "The state of this form is not recognized",
        action: "Draft"
    }
};

/**
 * Enum representing the status of entities in the system.
 */
export enum RecordStatus {
    Active = "Active",
    Inactive = "Inactive",
    Pending = "Pending",
    Archived = "Archived"
}

export const formColumns = [
    { title: 'ID', key: 'docId' },
    { title: 'Title', key: 'title' },
    { title: 'Form Number', key: 'formNumber' },
    { title: 'Form Type', key: 'formType' },
    { title: 'Created', key: 'meta.created' },
] as const;

export type FormColumnKey = typeof formColumns[number]['key'];
