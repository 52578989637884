import {
    ReactNode,
    FC,
    Key,
    Suspense,
    useCallback,
} from 'react';
import {
    Alert,
    Skeleton,
    Empty
} from 'antd';
import { Timestamp } from 'firebase/firestore';

import { FormVersion, Layout, TableDataType } from '../types/System.types';
import { dynamicUIComponentPropMapping } from './dynamicUIComponentPropMapping';
import { dynamicUILazyLoadComponent } from './dynamicUILazyLoadComponent';
import { TableEditColumn } from './atoms/TableEdit';

/**
 * Represents a dynamic UI component structure.
 */
export interface DynamicUIComponent {
    /** The name of the component to render */
    component: string;
    /** Optional field name that maps to the form data */
    field?: string;
    /** Optional props to pass to the component */
    props?: Record<string, string | number | boolean | DynamicUIComponent | Date | unknown>;
    /** Optional key for React rendering */
    key?: Key;
}

/**
 * Props for the DynamicUI component.
 */
interface DynamicUIProps {
    /** Form version data */
    versionData: FormVersion | null;
    /** Layout configuration for the form */
    formLayout: Layout | null;
    /** Loading state */
    loading: boolean;
    /** Error state */
    error: Error | null;
    /** Function to update form values */
    setValue: (value: Partial<FormVersion>) => void;
    /** Whether the form should be read-only */
    readOnly?: boolean;
}

const getDefaultValue = (type: string): TableDataType => {
    switch (type) {
        case 'number': return 0;
        case 'boolean': return false;
        case 'timestamp': return Timestamp.now();
        default: return '';
    }
};

/**
 * A component that dynamically renders UI based on provided layout and data.
 * It handles loading states, errors, and empty data scenarios.
 */
const DynamicUI: FC<DynamicUIProps> = ({ 
    versionData, 
    formLayout, 
    loading, 
    error, 
    setValue,
    readOnly = false 
}) => {
    const render = useCallback(({ 
        component, 
        field, 
        props, 
        key 
    }: DynamicUIComponent): ReactNode => {
        const processProps = (
            component: string,
            setValue: (value: Partial<FormVersion>) => void,
            props?: Record<string, any>,
            versionData?: Record<string, any> | null
        ): Record<string, any> => {
            const { 
                valueKey, 
                valueConverter, 
                changeHandlerConverter, 
                changeHandler, 
                typeCheck,
                readOnlyProp
            } = dynamicUIComponentPropMapping(component);

            const processedProps: Record<string, any> = {};
            
            if (field) {
                if (versionData && typeCheck(versionData[field])) {
                    processedProps[valueKey] = valueConverter(versionData?.[field] ?? '');
                }

                if (!readOnly) {
                    processedProps[changeHandler] = (event: any) => {
                        let newValue = event && event.target
                            ? event.target.value ?? event.target.checked
                            : event;

                        const processedValue = changeHandlerConverter(newValue);
                        setValue({ [field]: processedValue });
                    };
                } else if (readOnlyProp) {
                    processedProps[readOnlyProp] = true;
                }
            }

            if ((component === 'Table' || component === 'List') && field) {
                processedProps[valueKey] = versionData?.[field] || [];

                processedProps[changeHandler] = (index: number, key?: string, value?: TableDataType) => {
                    const updatedData = [...(versionData?.[field] || [])];

                    if (component === 'Table') {
                        updatedData[index] = { 
                            ...updatedData[index], 
                            [key as string]: value 
                        };
                    } else {
                        updatedData[index] = value as TableDataType;
                    }

                    setValue({ [field]: updatedData });
                };

                processedProps.onAddRow = () => {
                    const updatedData = [...(versionData?.[field] || [])];
                    
                    if (component === 'Table') {
                        const columns = props?.columns as TableEditColumn[] || [];
                        const newRow = columns.reduce<Record<string, TableDataType>>((acc, col) => ({
                            ...acc,
                            [col.dataIndex]: getDefaultValue(col?.celltype as string || 'string')
                        }), {});
                        updatedData.push(newRow);
                    } else {
                        updatedData.push(getDefaultValue(props?.listtype || 'string'));
                    }

                    setValue({ [field]: updatedData });
                };

                processedProps.onRemoveRow = (index: number) => {
                    const updatedData = [...(versionData?.[field] || [])];
                    updatedData.splice(index, 1);
                    setValue({ [field]: updatedData });
                };
            }

            for (const [propKey, layoutValue] of Object.entries(props || {})) {
                if (propKey === 'dataSource' && (component === 'Table' || component === 'List')) continue;
                
                if (Array.isArray(layoutValue)) {
                    processedProps[propKey] = layoutValue.map((item, index) =>
                        typeof item === 'object' && item !== null && 'component' in item
                            ? render({ ...item, key: `${propKey}-${index}` })
                            : item
                    );
                } else {
                    processedProps[propKey] = layoutValue;
                }
            }
            return processedProps;
        };

        try {
            const processedProps = processProps(component, setValue, props, versionData || undefined);

            const Component = dynamicUILazyLoadComponent(component);
    
            if (!Component) {
                console.warn(`Unknown component: `, component, `\nwith props: `, props);
                return <Alert 
                    type="warning" 
                    message={`Could not display ${component}.`} 
                    description={`Please review the layout settings.`} 
                />;
            }

            return (
                <Suspense fallback={<div>Loading...</div>} key={'s'+key}>
                    {( error ) && <Alert
                        type="warning"
                        message={`Error displaying ${component}:`}
                        description={`${error?.message || ''}`}
                    />}
                    <Component {...processedProps} key={key} />
                </Suspense>
            );
        } catch (error) {
            console.warn(`Error rendering component ${component}:`, error);
            return <Alert
                type="warning"
                message={`Error displaying ${component}:`}
                closable
            />;
        }

    }, [versionData, setValue, error, readOnly]);

    if (loading) {
        return <Skeleton />;
    }

    if (!versionData || !formLayout) {
        return <Skeleton />;
    }

    return render(formLayout as DynamicUIComponent);
};

export default DynamicUI;