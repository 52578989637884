import { FC, createContext, PropsWithChildren } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';

import { algoliaConfig } from '../config';

const searchClient = algoliasearch(algoliaConfig.appId, algoliaConfig.searchApiKey);

/**
 * The search context type.
 */
interface AlgoliaContextType {
    /**
     * The search client instance.
     */
    searchClient: ReturnType<typeof algoliasearch>;
}

const SearchContext = createContext<AlgoliaContextType | undefined>(undefined);

/**
 * SearchProvider component
 * 
 * Provides Algolia search functionality to its children components
 * using React Context and InstantSearch.
 * 
 * @param {PropsWithChildren} props - The component props
 * @returns {JSX.Element} The wrapped children with search context
 */
export const SearchProvider: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SearchContext.Provider value={{ searchClient }}>
            <InstantSearch 
                searchClient={searchClient} 
                indexName={algoliaConfig.allIndexName}
                future={{ preserveSharedStateOnUnmount: true }}
            >
                {children}
            </InstantSearch>
        </SearchContext.Provider>
    );
};