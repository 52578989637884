import { FC } from 'react';

import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import FormsTable from '../components/artifacts/FormsTable';
import FormsTableControls from '../components/artifacts/FormsTableControls';

const FormsPage: FC = () => {
    return (
        <FlexPage>
            <Navb />
            <FlexContent>
                <FormsTableControls />
                <FormsTable />
            </FlexContent>
        </FlexPage>
    );
};

export default FormsPage;

