import {
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
    Functions,
} from 'firebase/functions';
import {
    DocumentData,
    Timestamp,
} from 'firebase/firestore';

import { environmentType } from '../../config';
import { 
    FormStateId,
    FormVersion,
    formStateDescription,
    formStateId,
    FormType,
    Form,
    Layout
} from '../../types/System.types';
import { useBoundDoc } from '../data/useBoundDoc';
import { useBoundCollection } from '../data/useBoundCollection';

/**
 * Request object for setting a new form state.
 */
interface SetStateRequest {
    /** Unique identifier of the form */
    formId: string;
    /** The new state to set for the form */
    newState: FormStateId;
}

/**
 * Comprehensive return type for the useForms hook.
 */
interface UseFormStatesReturn {
    /** Function to update the form's state */
    setFormState: (requestData: SetStateRequest) => Promise<FormStateId>;
    /** Function to create a new version of the form */
    createFormVersion: (requestData: SetStateRequest) => Promise<FormStateId>;
    /** Indicates if any data fetching or operations are in progress */
    loading: boolean;
    /** Any error that occurred during data fetching or operations */
    error: Error | null;
    /** Current form data */
    formData: Form | null;
    /** Data for the currently selected form version */
    formVersionData: FormVersion | null;
    /** All versions of the current form */
    formVersionsData: FormVersion[] | null;
    /** Layout for the current form version */
    formLayout: Layout | null;
    /** Available version options for the form */
    versionOptions: VersionOption[];
    /** States that the form can transition to from its current state */
    allowedNextStates: FormStateId[];
    /** Function to update the value of the current form version */
    setValue: (value: Partial<Form>) => void;
    /** Function to update the header value of the form */
    setFormHeaderValue: (value: Partial<Form>) => void;
    /** The ID of the currently selected form version */
    selectedVersionId: string | undefined;
    /** Function to select a specific version of the form */
    selectVersionId: (id: string) => void;
    /** Function to create a new form */
    createForm: (requestData: CreateFormRequest) => Promise<string>;
}

/**
 * Represents a version options for a form.
 */
interface VersionOption {
    /** Unique identifier for the version */
    id: string;
    /** Title of the version, typically describing its state */
    title: string;
    /** Optional description of the version */
    description?: string;
    /** Optional action associated with this version */
    action?: string;
    /** Timestamp when this version was created */
    created?: string;
    /** Full data associated with this version */
    data: DocumentData;
}

/**
 * Request object for creating a new form.
 */
interface CreateFormRequest {
  formType: string;
  title?: string;
  organization?: string;
  userGroup?: string;
  setFormData?: Partial<Form>;
  setVersionData?: Partial<FormVersion>;
}

/**
 * Filters for the forms list
 */
export interface FormFilters {
    formType?: string;
    organization?: string;
    title?: string;
    // Add more filter fields as needed
}

/**
 * Result of the list function
 */
interface Result<T> {
    items: T[];
}

const LOCAL_STORAGE_KEY = 'useForms_preferences';

/**
 * Custom hook for managing form states and versions.
 * 
 * @param formId - The ID of the form to manage.
 * @returns An object containing form management functions and state.
 * 
 * @property {function} setFormState - Updates the state of the form.
 * @property {function} createFormVersion - Creates a new version of the form.
 * @property {boolean} loading - Indicates if any data is currently loading.
 * @property {Error|null} error - Any error that occurred during data fetching or operations.
 * @property {Form|null} formData - The current form data.
 * @property {FormVersion|null} formVersionData - The current form version data.
 * @property {Record<string, FormVersion>|null} formVersionsData - All versions of the form.
 * @property {Layout|null} formLayout - The layout for the current form version.
 * @property {VersionOption[]} versionOptions - Available version options for the form.
 * @property {FormStateId[]} allowedNextStates - Allowed next states for the current form version.
 * @property {function} setValue - Updates the value of the current form version.
 * @property {function} setFormHeaderValue - Updates the header value of the form.
 * @property {function} selectVersionId - Selects a specific version of the form.
 * @property {function} createForm - Creates a new form.
 */
export const useForms = (formId?: string): UseFormStatesReturn => {
    const [functions, setFunctions] = useState<Functions | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [versionOptions, setVersionOptions] = useState<VersionOption[]>([]);
    const [allowedNextStates, setAllowedNextStates] = useState<FormStateId[]>([formStateId.Draft]);
    const [selectedVersionId, selectVersionId] = useState<string | undefined>(undefined);
    const [selectedLayoutId, selectLayout] = useState<string | undefined>(undefined);

    const {
        data: formData,
        loading: formLoading,
        error: formError,
        debouncedSet: setFormHeaderValue
    } = useBoundDoc<Form>({
        path: 'forms',
        docId: formId,
        enabled: !!formId
    });

    const { 
        data: formVersionsData, 
        loading: formVersionsLoading, 
        error: formVersionsError 
    } = useBoundCollection<FormVersion>({
        path: formId ? `forms/${formId}/formVersions` : ''
    });
    
    const {
        data: formVersionData,
        loading: formVersionLoading,
        error: formVersionError,
        debouncedSet: setValue
    } = useBoundDoc<FormVersion>({
        path: formId ? `forms/${formId}/formVersions` : '',
        docId: selectedVersionId,
        enabled: !!formId && !!selectedVersionId
    });

    const {
        data: formTypeData,
        loading: formTypeLoading,
        error: formTypeError
    } = useBoundDoc<FormType>({
        path: 'formTypes',
        docId: formData?.formType,
        enabled: !!formData?.formType
    });

    const {
        data: formLayout,
        loading: layoutLoading,
        error: layoutError,
    } = useBoundDoc<Layout>({
        path: 'layouts',
        docId: selectedLayoutId,
        enabled: !!selectedLayoutId
    });

    useEffect(() => {
        if (formVersionsData) {
            const versionOptions = formVersionsData.map(version => ({
                id: version.docId  || '', 
                created: version.meta?.created instanceof Timestamp 
                    ? version.meta.created.toDate().toLocaleString() 
                    : '',
                title: formStateDescription[version.state as FormStateId]?.title || formStateDescription['*'].title,
                description: formStateDescription[version.state as FormStateId]?.description,
                action: formStateDescription[version.state as FormStateId]?.action,
                data: version
            }));
            setVersionOptions(versionOptions);
        }
    }, [formVersionsData]);

    useEffect(() => {
        selectLayout(formVersionData?.layoutId || formTypeData?.defaultLayoutId);
    }, [formVersionData, formTypeData]);

    useEffect(() => {
        if (formData?.currentVersionId && formTypeData?.possibleStates) {
            const currentState = formVersionsData.find(v => v.docId === formData.currentVersionId)?.state || 'Draft';
            const allowedStates = formTypeData.possibleStates[currentState]?.allowedNextStates || [];
            setAllowedNextStates(allowedStates);
        }
    }, [formData, formTypeData, formVersionsData]);

    useEffect(() => {
        if (selectedVersionId === undefined && formData?.currentVersionId) {
            selectVersionId(formData.currentVersionId);
        }
    }, [formData, selectedVersionId]);

    useEffect(() => {
        const initializeFunctions = async () => {
            const funcs = getFunctions();
            if (environmentType === 'development') {
                try {
                    const response = await fetch('http://localhost:8080');
                    if (response.ok) {
                        connectFunctionsEmulator(funcs, 'localhost', 5001);
                        console.log('useForms: Connected to Firebase Functions emulator');
                    }
                } catch (error) {
                    console.log('useForms: Firebase Functions emulator is not running');
                }
            }
            setFunctions(funcs);
        };

        initializeFunctions();
    }, []);

    const setFormState = async (requestData: SetStateRequest): Promise<FormStateId> => {
        if (!functions) {
            throw new Error('Firebase Functions not initialized');
        }

        setIsLoading(true);
        setError(null);

        const callSetFormState = httpsCallable<SetStateRequest, FormStateId>(functions, 'setFormState');

        try {
            const result = await callSetFormState(requestData);
            
            return result.data;
        } catch (error) {
            const errorMessage = `Failed to set form state: ${error}`;
            console.error(`Error calling setFormState function:`, error);
            setError(new Error(errorMessage));
            throw new Error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const createFormVersion = async (requestData: SetStateRequest): Promise<FormStateId> => {
        if (!functions) {
            throw new Error('Firebase Functions not initialized');
        }

        setIsLoading(true);
        setError(null);

        const callCreateFormVersion = httpsCallable<SetStateRequest, FormStateId>(functions, 'createFormVersion');

        try {
            const result = await callCreateFormVersion(requestData);
            selectVersionId(result.data);
            
            return result.data;
        } catch (error) {
            const errorMessage = `Failed to create form version: ${error}`;
            console.error(`Error calling createFormVersion function:`, error);
            setError(new Error(errorMessage));
            throw new Error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const createForm = async (requestData: CreateFormRequest): Promise<string> => {
        if (!functions) {
            throw new Error('Firebase Functions not initialized');
        }

        setIsLoading(true);
        setError(null);

        const callCreateForm = httpsCallable<CreateFormRequest, string>(functions, 'createForm');

        try {
            const result = await callCreateForm(requestData);
            return result.data;
        } catch (error) {
            const errorMessage = `Failed to create form: ${error}`;
            console.error(`Error calling createForm function:`, error);
            setError(new Error(errorMessage));
            throw new Error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return { 
        setFormState, 
        createFormVersion,
        createForm,
        loading: isLoading || formVersionsLoading || formVersionLoading || formLoading || formTypeLoading || layoutLoading,
        error: error || formVersionsError || formVersionError || formError || formTypeError || layoutError, 
        formData,
        formVersionsData, 
        formVersionData,
        formLayout,
        versionOptions,
        allowedNextStates,
        setValue,
        setFormHeaderValue,
        selectedVersionId,
        selectVersionId,
    };
};