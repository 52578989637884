import { 
    lazy, 
    ComponentType,
    LazyExoticComponent,
    createElement
} from "react";
import Alert from 'antd/es/alert';

import FlexBox from './atoms/FlexBox';
import FlexCard from './atoms/FlexCard';
import TableEdit from './atoms/TableEdit';
import ListEdit from './atoms/ListEdit';

const componentMap: Record<string, LazyExoticComponent< ComponentType<any> >> = {};

const localComponents: Record<string, ComponentType<any>> = {
    FlexBox,
    FlexCard,
    Table: TableEdit,
    List: ListEdit,
    div: (props: any) => createElement(`div`, props),
    p: (props: any) => createElement(`p`, props),
    span: (props: any) => createElement(`span`, props),
    h1: (props: any) => createElement(`h1`, props),
    h2: (props: any) => createElement(`h2`, props),
    h3: (props: any) => createElement(`h3`, props),
    h4: (props: any) => createElement(`h4`, props),
    h5: (props: any) => createElement(`h5`, props),
    h6: (props: any) => createElement(`h6`, props),
    a: (props: any) => createElement(`a`, props),
    img: (props: any) => createElement(`img`, props),
    ul: (props: any) => createElement(`ul`, props),
    ol: (props: any) => createElement(`ol`, props),
    li: (props: any) => createElement(`li`, props),
    section: (props: any) => createElement(`section`, props),
    article: (props: any) => createElement(`article`, props),
    header: (props: any) => createElement(`header`, props),
    footer: (props: any) => createElement(`footer`, props),
    nav: (props: any) => createElement(`nav`, props),
    main: (props: any) => createElement(`main`, props),
    aside: (props: any) => createElement(`aside`, props),
    figure: (props: any) => createElement(`figure`, props),
    figcaption: (props: any) => createElement(`figcaption`, props),
};

export const dynamicUILazyLoadComponent = (component: string) => {
    if (!component) {
        console.warn(`Component is undefined`);
        throw new Error(`Component is undefined`);
    }

    if (component in localComponents) return localComponents[component];

    const errorComponent = () => createElement(Alert, {
        type: "warning",
        message: `Could not display a field ${component}.`,
        closable: true,
        showIcon: true
    })

    if (!componentMap[component]) {
        const [aComponent, subComponent] = component.split(`.`) ?? [];
        componentMap[component] = lazy(() =>
            import(`antd`).then((module: any) => {
                if (subComponent) {
                    return {
                        default: (module[aComponent]?.[subComponent] as ComponentType<any>) || errorComponent
                    };
                }
                if (module[component]) {
                    return {
                        default: (module[component] as ComponentType<any>) || errorComponent
                    };
                }
                return { default: errorComponent };
            }).catch(() => {
                return { default: errorComponent };
            })
        );
    }

    return componentMap[component];
};