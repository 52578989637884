import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import { Color } from '@rc-component/color-picker';
// import { TableDataType } from "../types/System.types";

export const dynamicUIComponentPropMapping = (component: string) => {
    const propMapping = propMaps[component] || {};
    return {
        valueKey: propMapping.valueKey ?? 'value',
        valueConverter: propMapping.valueConverter ?? ((v: any) => v),
        changeHandlerConverter: propMapping.changeHandlerConverter ?? ((v: any) => v),
        changeHandler: propMapping.changeHandler ?? 'onChange',
        typeCheck: propMapping.typeCheck ?? ((v) => typeof v === 'string'),
        readOnlyProp: propMapping.readOnlyProp ?? 'disabled'
    };
};

const propMaps: Record<string, {
    valueKey?: string | null;
    changeHandlerConverter?: (...args: any[]) => any;
    valueConverter?: (...args: any[]) => any;
    changeHandler?: string;
    typeCheck?: (value: any) => boolean;
    readOnlyProp?: string;
    // tableDataType?: TableDataType;
}> = {
    Input: {
        readOnlyProp: 'readOnly'
    },
    TextArea: {
        readOnlyProp: 'readOnly'
    },
    InputNumber: {
        typeCheck: (v) => typeof v === 'number',
        readOnlyProp: 'readOnly'
    },
    Checkbox: {
        valueKey: 'checked',
        typeCheck: (v) => typeof v === 'boolean',
    },
    Radio: {
        valueKey: 'checked',
        typeCheck: (v) => typeof v === 'boolean',
    },
    Switch: {
        valueKey: 'checked',
        typeCheck: (v) => typeof v === 'boolean',
    },
    Slider: {
        valueKey: 'value',
        typeCheck: (v) => typeof v === 'number',
    },
    Rate: {
        valueKey: 'value',
        typeCheck: (v) => typeof v === 'number',
    },
    Mentions: {
        valueKey: 'value',
        readOnlyProp: 'readOnly'
    },
    TreeSelect: {
        changeHandler: 'onSelect',
    },
    Cascader: {
        changeHandler: 'onSelect',
    },
    Transfer: {
        changeHandler: 'onSelectChange',
    },
    DatePicker: {
        changeHandler: 'onChange',
        changeHandlerConverter: (value: Timestamp) => Timestamp.fromDate(value.toDate()),
        valueConverter: (value: any) => value ? dayjs(value.toDate()) : null,
        typeCheck: (v: any) => v instanceof Timestamp,
    },
    TimePicker: {
        changeHandler: 'onChange',
        changeHandlerConverter: (value: Timestamp) => Timestamp.fromDate(value.toDate()),
        valueConverter: (value: any) => value ? dayjs(value.toDate()) : null,
        typeCheck: (v: any) => v instanceof Timestamp,
    },
    RangePicker: {
        changeHandler: 'onChange',
        changeHandlerConverter: (value: Timestamp[]) => value.map((v) => Timestamp.fromDate(v.toDate())),
        valueConverter: (value: any) => value ? value.map((v: Timestamp) => dayjs(v.toDate())) : null,
        typeCheck: (v: any) => Array.isArray(v) && v.every((v: any) => v instanceof Timestamp),
    },
    Calendar: {
        changeHandler: 'onChange',
        changeHandlerConverter: (value: Timestamp) => Timestamp.fromDate(value.toDate()),
        valueConverter: (value: any) => value ? dayjs(value.toDate()) : null,
        typeCheck: (v: any) => v instanceof Timestamp,
    },
    Upload: {
        valueKey: 'none',
    },
    ColorPicker: {
        valueKey: 'value',
        changeHandler: 'onChange',
        changeHandlerConverter: (value: Color) => value.toHexString(),
    },
    Pagination: {
        valueKey: 'current',
        typeCheck: (v) => typeof v === 'number',
    },
    Steps: {
        valueKey: 'current',
        typeCheck: (v) => typeof v === 'number'
    },
    Tabs: {
        valueKey: 'activeKey'
    },
    Carousel: {
        valueKey: 'current',
        typeCheck: (v) => typeof v === 'number'
    },
    Collapse: {
        valueKey: 'activeKey'
    },
    Tree: {
        valueKey: 'checkedKeys',
        typeCheck: (v) => Array.isArray(v),
    },
    Progress: {
        valueKey: 'percent',
        typeCheck: (v) => typeof v === 'number'
    },
    Statistic: {
        valueKey: 'value'
    },
    Countdown: {
        valueKey: 'value'
    },
    Anchor: {
        valueKey: 'activeLink'
    },
    Segmented: {
        valueKey: 'value',
    },
    Table: {
        valueKey: 'dataSource',
        changeHandler: 'onCellChange',
        readOnlyProp: 'readOnly',
    },
    List: {
        valueKey: 'dataSource',
        changeHandler: 'onCellChange',
        readOnlyProp: 'readOnly',
        typeCheck: (v) => Array.isArray(v),
    },
    TreeTable: {
        valueKey: 'checkedKeys'
    }
};