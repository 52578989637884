import {
    FC,
    useState,
    useEffect,
    useCallback,
    useContext,
} from 'react';
import {
    Skeleton,
    theme,
    Typography,
    Card,
    Grid,
    message,
    List,
    Descriptions,
    Tag,
    Row,
    Col,
} from 'antd';

import FlexPage from '../components/atoms/FlexPage';
import FlexBox from '../components/atoms/FlexBox';
import Navb from '../components/Navb';
import { ArtifactsContext } from '../providers/ArtifactsProvider';
import { useEntityRecords } from '../use/artifacts/useEntityRecords';
import { Entity, EntityRecord, DocDataWithId, RecordStatus } from '../types/System.types';

const { Text } = Typography;
const { useBreakpoint } = Grid;
const { Paragraph } = Typography;

const EntitiesPage: FC = () => {
    const { token } = theme.useToken();
    const screens = useBreakpoint();
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedEntity, setSelectedEntity] = useState<DocDataWithId<Entity> | null>(null);
    const [selectedRecord, setSelectedRecord] = useState<EntityRecord | null>(null);

    const artifactsContext = useContext(ArtifactsContext);
    if (!artifactsContext) {
        throw new Error('EntitiesPage must be used within an ArtifactsProvider');
    }
    const { entities } = artifactsContext;

    const {
        entityRecords,
        loading: entityRecordsLoading,
        error: entityRecordsError,
    } = useEntityRecords(selectedEntity?.docId || '');

    useEffect(() => {
        if (entityRecordsError) {
            messageApi.error(`Failed to fetch entity records: ${entityRecordsError.message}`);
        }
    }, [entityRecordsError, messageApi]);

    const handleEntitySelect = useCallback((entity: DocDataWithId<Entity>) => {
        setSelectedEntity(entity);
        setSelectedRecord(null);
    }, []);

    const handleEntityRecordSelect = useCallback((entityRecord: EntityRecord) => {
        setSelectedRecord(entityRecord);
    }, []);

    const renderRecordDetails = (record: EntityRecord) => {
        const knownFields = ['name', 'status', 'meta'];
        const unknownFields = Object.keys(record).filter(key => !knownFields.includes(key));

        return (
            <FlexBox
                column
                alignStart
                style={{ width: '100%', maxWidth: 800 }}
                gap={0}
            >
                <Text>{record.name}</Text>
                <Card styles={{
                    body: {
                        padding: 0,
                    },
                }} >
                    <Descriptions
                        bordered
                        column={1}
                    >
                        <Descriptions.Item label="Status">
                            <Tag color={record.status === RecordStatus.Active ? 'green' : 'red'}>
                                {record.status}
                            </Tag>
                        </Descriptions.Item>
                        {record.meta && (
                            <>
                                <Descriptions.Item label="Created">
                                    {record.meta.created instanceof Date ? record.meta.created.toLocaleString() : 'Unknown'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Last Modified">
                                    {record.meta.lastModified instanceof Date ? record.meta.lastModified.toLocaleString() : 'Unknown'}
                                </Descriptions.Item>
                            </>
                        )}
                        {unknownFields.map(field => (
                            <Descriptions.Item label={field} key={field}>
                                {renderFieldValue(record[field])}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </Card>
            </FlexBox>
        );
    };

    const renderFieldValue = (value: any) => {
        if (typeof value === 'string') {
            return (
                <Paragraph
                    ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                    style={{ marginBottom: 0, wordBreak: 'break-word' }}
                >
                    {value}
                </Paragraph>
            );
        } else if (typeof value === 'number' || typeof value === 'boolean') {
            return String(value);
        } else if (value instanceof Date) {
            return value.toLocaleString();
        } else if (Array.isArray(value)) {
            return (
                <Paragraph
                    ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}
                    style={{ marginBottom: 0 }}
                >
                    {value.join(', ')}
                </Paragraph>
            );
        } else if (typeof value === 'object' && value !== null) {
            return (
                <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                    <Descriptions bordered column={1} size="small">
                        {Object.entries(value).map(([key, val]) => (
                            <Descriptions.Item label={key} key={key}>
                                {renderFieldValue(val)}
                            </Descriptions.Item>
                        ))}
                    </Descriptions>
                </div>
            );
        }
        return 'N/A';
    };

    const listStyle = {
        border: '1px solid ' + token.colorBorder,
        maxHeight: screens.xs ? '200px' : '400px',
        overflowY: 'auto' as const,
        width: '100%',
    };

    const listItemStyle = (isSelected: boolean) => ({
        paddingBlock: 10,
        paddingInline: 10,
        cursor: 'pointer',
        backgroundColor: isSelected ? token.colorPrimary : 'transparent',
    });

    return (
        <FlexPage>
            {contextHolder}
            <Navb />
            <Row
                gutter={[16, 16]}
                style={{
                    width: '100%',
                    maxWidth: 2000,
                    padding: 20
                }}
            >
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Text>Entities</Text>
                    {entities.loading ? (
                        <Skeleton active />
                    ) : (
                        <List
                            dataSource={entities.data as DocDataWithId<Entity>[]}
                            renderItem={(entity: DocDataWithId<Entity>) => (
                                <List.Item
                                    onClick={() => handleEntitySelect(entity)}
                                    style={listItemStyle(selectedEntity?.docId === entity.docId)}
                                >
                                    <Text>{entity.name}</Text>
                                </List.Item>
                            )}
                            style={listStyle}
                        />
                    )}
                </Col>

                {!selectedEntity ? (
                    <Text>Select an entity</Text>
                ) : entityRecordsLoading ? (
                    <Skeleton active />
                ) : (
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Text>Entity Records</Text>
                        <List
                            dataSource={entityRecords}
                            renderItem={(record: EntityRecord) => (
                                <List.Item
                                    onClick={() => handleEntityRecordSelect(record)}
                                    style={listItemStyle(selectedRecord?.docId === record.docId)}
                                >
                                    <Text>{record.name}</Text>
                                </List.Item>
                            )}
                            style={listStyle}
                        />
                    </Col>
                )}

                {selectedRecord && (
                    <Col xs={24} md={16} lg={12}>
                        {renderRecordDetails(selectedRecord)}
                    </Col>
                )}
            </Row>
        </FlexPage>
    );
};

export default EntitiesPage;