import React, { 
  useState, 
  useContext, 
  useEffect 
} from 'react';
import { 
  Form,
  Spin,
  Layout,
  Button,
  Typography,
  Alert,
  Divider,
  Input 
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { 
  useNavigate, 
  useLocation 
} from 'react-router-dom';

import useFirebaseAuth from '../use/useFirebaseAuth';
import { ThemeContext } from '../providers/ThemeProvider';
import FlexBox from '../components/atoms/FlexBox';

const LoginPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const [registerMode, setRegisterMode] = useState<boolean>(false);
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);
  const { user, loading, login, signup, signInWithGoogle } = useFirebaseAuth();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const mode = params.get('mode');
    setRegisterMode(mode === 'signup');
  }, [location]);

  const handleLogin = async (values: { email: string; password: string }) => {
    try {
      await login(values.email, values.password);
    } catch (e) {
      setError(`Sorry, that didn't work, please try again.`);
    }
  };

  const handleSignup = async (values: { email: string; password: string }) => {
    try {
      await signup(values.email, values.password);
    } catch (error) {
      setError(`Sorry, that didn't work, please try again.`);
    }
  };

  const onFinish = (values: { email: string; password: string }) => {
    setError(null);
    if (registerMode) {
      handleSignup(values);
    } else {
      handleLogin(values);
    }
  };

  const toggleRegisterMode = () => {
    setError(null);
    setRegisterMode(!registerMode);
  };

  return (
    <Layout
      style={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!loading ?
        <FlexBox 
          column 
          noGrow 
          stretch 
          gap={0}
        >
          <img
            src={darkMode ? '/art/syncretic-logo-word2.svg' : '/art/syncretic-logo-word2.svg'}
            alt='Syncretic'
            style={{
              width: '80%',
              maxWidth: '300px',
            }}
          />
          <Typography.Title
            level={4}
            style={{
              textAlign: 'center',
              fontWeight: '400',
              letterSpacing: 5,
              marginBottom: '40px',
            }}
          >
            ENTERPRISE SYSTEM
          </Typography.Title>
          <Divider
            style={{
              height: '10px !important',
              maxHeight: '10px !important',
              width: '10px !important',
              maxWidth: '0px !important',
            }}
          />
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{
              textAlign: 'center',
              maxWidth: '300px',
              paddingTop: '32px'
            }}
          >
            <Form.Item>
              <Button
                onClick={signInWithGoogle}
                size='large'
                style={{
                  width: '100%',
                  backgroundColor: '#394A5C',
                  color: '#fff',
                  border: 'none',
                  textAlign: 'center',
                }}
              >
                <img
                  src='/art/Google__G__Logo.svg'
                  alt="Google sign-in"
                  style={{
                    paddingRight: 10,
                    height: 20,
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                />
                Sign in with Google
              </Button>
            </Form.Item>
            <Divider>
              OR 
            </Divider>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Alert
                message={error}
                type='error'
                style={{
                  visibility: error ? 'visible' : 'hidden',
                  height: 40,
                  fontSize: '14px',
                }}
              />
            </Form.Item> 
            <Form.Item>
              <Button type="link" onClick={toggleRegisterMode} style={{ width: '100%' }}>
                {registerMode ? 'Already have an account? Log-in.' : "Don't have an account? Register."}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                style={{ width: '100%' }}
              >
                {registerMode ? 'Register' : 'Log in'}
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="link"
                size='small'
                onClick={() => navigate('/password-reset')}
                style={{ width: '100%' }}
              >
                Reset password
              </Button>
            </Form.Item>
          </Form>
        </FlexBox>
        :
        <Spin size='large' />
      }
    </Layout>
  );
}

export default LoginPage;
