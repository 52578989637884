import {
    FC,
    useState,
    useCallback,
    useEffect
} from 'react';
import { useParams } from 'react-router-dom';
import {
    Alert,
    Skeleton
} from 'antd';

import { FormStateId } from '../types/System.types';
import FlexPage from '../components/atoms/FlexPage';
import FlexContent from '../components/atoms/FlexContent';
import Navb from '../components/Navb';
import DynamicUI from '../components/DynamicUI';
import FormPageHeader from '../components/artifacts/FormPageHeader';
import { useForms } from '../use/artifacts/useForms';
import { useCheckDocAccess } from '../use/data/useCheckDocAccess';

const FormPage: FC = () => {
    const { id: formId } = useParams<{ id: string }>();

    const [issueLoading, setIssueLoading] = useState(false);
    const [nextState, setNextState] = useState<FormStateId | undefined>(undefined);
    const {
        createFormVersion,
        loading,
        error,
        versionOptions,
        formVersionData,
        selectedVersionId,
        selectVersionId,
        allowedNextStates,
        formLayout,
        setValue,
    } = useForms(formId);

    const {
        canRead,
        canWrite,
    } = useCheckDocAccess(formId ? `/forms/${formId}/formVersions` : null, selectedVersionId || null);

    const handleCreateVersion = useCallback(async (selectedState: FormStateId) => {
        setIssueLoading(true);
        try {
            if (formId) {
                await createFormVersion({ formId, newState: selectedState });
                await new Promise(resolve => setTimeout(resolve, 750));
            }
        } finally {
            setIssueLoading(false);
        }
    }, [formId, createFormVersion]);

    const versionTitle = versionOptions.find(option => option.id === formVersionData?.docId)?.title;

    useEffect(() => {
        if (allowedNextStates.length > 0 && !nextState) {
            setNextState(allowedNextStates[0]);
        }
    }, [allowedNextStates, nextState]);

    return (
        <FlexPage>
            <Navb />
            <FlexContent>
                {loading ? (
                    <Skeleton active />
                ) : (
                    <>
                        {error && (
                            <Alert
                                message={error.message}
                                type="error"
                                showIcon
                                closable
                                style={{
                                    width: '100%',
                                }}
                            />
                        )}
                        <FormPageHeader
                            formId={formId || ''}
                            versionTitle={versionTitle}
                            versionOptions={versionOptions}
                            selectVersionId={selectVersionId}
                            allowedNextStates={allowedNextStates}
                            handleCreateVersion={handleCreateVersion}
                            issueLoading={issueLoading}
                            loading={loading}
                            canRead={canRead}
                            canWrite={canWrite}
                        />
                        <DynamicUI
                            versionData={formVersionData}
                            formLayout={formLayout}
                            loading={loading}
                            error={error}
                            setValue={setValue}
                            readOnly={!canWrite}
                        />
                    </>
                )}
            </FlexContent>
        </FlexPage>
    );
};

export default FormPage;