import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
    Breadcrumb,
    Dropdown,
    MenuProps,
    Button,
    Typography,
    Tooltip
} from 'antd';
import {
    FormOutlined,
    PlusOutlined,
    DownOutlined
} from '@ant-design/icons';

import FlexCard from '../atoms/FlexCard';
import FlexBox from '../atoms/FlexBox';
import DocumentAccess from '../atoms/DocumentAccess';
import { FormStateId } from '../../types/System.types';

const { Text } = Typography;

interface FormPageHeaderProps {
    formId: string;
    versionTitle?: string;
    versionOptions: Array<{ id: string; title: string; description?: string; created?: string }>;
    selectVersionId: (id: string) => void;
    allowedNextStates: FormStateId[];
    handleCreateVersion: (state: FormStateId) => Promise<void>;
    issueLoading: boolean;
    loading: boolean;
    canRead: boolean;
    canWrite: boolean;
}

const FormPageHeader: FC<FormPageHeaderProps> = ({
    formId,
    versionTitle,
    versionOptions,
    selectVersionId,
    allowedNextStates,
    handleCreateVersion,
    issueLoading,
    loading,
    canRead,
    canWrite,
}) => {
    const versionItems = {
        items: versionOptions.map(option => ({
            key: option.id,
            label: (
                <FlexBox>
                    <Tooltip title={option?.description}>
                        <Text>{option?.title}</Text>
                    </Tooltip>
                    <Text type="secondary">{option?.created}</Text>
                </FlexBox>
            ),
            onClick: () => selectVersionId(option.id),
        }))
    };

    const createItems: MenuProps['items'] = allowedNextStates.map(state => ({
        key: state,
        label: state,
        onClick: () => handleCreateVersion(state)
    }));

    return (
        <FlexCard
            row
            wrap
            gap={10}
            style={{
                minWidth: '100%',
            }}
        >
            <FlexBox noGrow>
                <Breadcrumb
                    separator="|"
                    items={[
                        {
                            title: <Link to="/forms"><FormOutlined /> Forms</Link>,
                        },
                        {
                            title: formId,
                        },
                        {
                            title: versionTitle,
                            menu: versionItems,
                        }
                    ]}
                />
                <DocumentAccess
                    canRead={canRead}
                    canWrite={canWrite}
                />
            </FlexBox>
            <FlexBox stretch justifyEnd>
                <Dropdown
                    menu={{ items: createItems }}
                    trigger={['click']}
                    disabled={issueLoading || loading || allowedNextStates.length === 0}
                >
                    <Button
                        type="link"
                        icon={<PlusOutlined />}
                        disabled={issueLoading || loading || allowedNextStates.length === 0}
                    >
                        New Version <DownOutlined />
                    </Button>
                </Dropdown>
            </FlexBox>
        </FlexCard>
    );
};

export default FormPageHeader;