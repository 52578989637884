import { FC, ReactNode, createContext, useMemo } from 'react';
import { useBoundCollection } from '../use/data/useBoundCollection';
import { Form, FormType, Entity } from '../types/System.types';

/**
 * Type definition for the ArtifactsContext.
 */
export interface ArtifactsContextType {
    /** The bound list of forms and related properties. */
    forms: ReturnType<typeof useBoundCollection>;
    /** The bound list of form types and related properties. */
    formTypes: ReturnType<typeof useBoundCollection>;
    /** The bound list of entities and related properties. */
    entities: ReturnType<typeof useBoundCollection>;
}

export const ArtifactsContext = createContext<ArtifactsContextType | undefined>(undefined);

/**
 * Props for the ArtifactsProvider component.
 */
interface ArtifactsProviderProps {
    children: ReactNode;
    /**
     * Used for testing, a hook that returns the bound list of forms and related properties.
     * If not provided, the default hook from useBoundCollection will be used.
     */
    formsHook?: typeof useBoundCollection;
    /**
     * Used for testing, a hook that returns the bound list of form types and related properties.
     * If not provided, the default hook from useBoundCollection will be used.
     */
    formTypesHook?: typeof useBoundCollection;
    /**
     * Used for testing, a hook that returns the bound list of entities and related properties.
     * If not provided, the default hook from useBoundCollection will be used.
     */
    entitiesHook?: typeof useBoundCollection;
}

export const ArtifactsProvider: FC<ArtifactsProviderProps> = ({ 
    children, 
    formsHook = useBoundCollection<Form>,
    formTypesHook = useBoundCollection<FormType>,
    entitiesHook = useBoundCollection<Entity>
}) => {
    const forms = formsHook<Form>({
        path: 'forms',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
        initialLimit: 50
    });
    const formTypes = formTypesHook<FormType>({
        path: 'formTypes',
        initialOrderBy: [{ field: 'description.shortLabel', direction: 'asc' }],
        initialLimit: 50
    });
    const entities = entitiesHook<Entity>({
        path: 'entities',
        initialOrderBy: [{ field: 'meta.created', direction: 'desc' }],
        initialLimit: 50
    });

    const value = useMemo(() => ({
        forms,
        formTypes,
        entities
    }), [forms, formTypes, entities]);

    return (
        <ArtifactsContext.Provider value={value}>
            {children}
        </ArtifactsContext.Provider>
    );
};
